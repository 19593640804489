<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-12 m-12 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Phiếu mượn vật tư</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="page-list-action">
                                    <md-button v-shortkey="['ctrl', 'p']" @shortkey="print()" @click="print()" class="md-raised md-view btn-add"><span>P</span>rint<md-tooltip>Print</md-tooltip></md-button>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <div id="store-view" style="width: 1000px; display: flex; flex-direction: column; justify-content: center;margin: 10px auto;">
                                <div style="height: 100px; display: flex; justify-content: space-between;">
                                    <div style="border-bottom: 1px solid #999; 
                                        width: 25%; display: flex;align-items: center; justify-content: center;
                                        border-top: 1px solid #999;border-left: 1px solid #999;">
                                        <img style="height: 50px; width: auto;" src="../../../assets/images/logo.png" />
                                    </div>
                                    <div style="border-bottom: 1px solid #999;
                                        border-left: 1px solid #999;
                                        border-top: 1px solid #999;
                                        border-right: 1px solid #999;
                                        width: 45%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        text-transform: uppercase;
                                        font-weight: bold;">
                                        <div class="title"><h2>Phiếu mượn vật tư</h2></div>
                                    </div>
                                    <div style="border-top: 1px solid #999; border-right: 1px solid #999;border-bottom: 1px solid #999; width: 30%; display: flex; flex-direction: column; justify-content: space-around; padding: 10px;">
                                        <div><span style="font-weight: bold;">Số: </span><span>{{entity.exportCode}}</span></div>
                                        <div><span style="font-weight: bold;">Ngày: </span><span>{{entity.exportDate}}</span></div>
                                    </div>
                                </div>
                                <div style="display: flex; flex-direction: column; padding: 10px;">
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Họ và tên người giao hàng:</div>
                                        <div style="font-size: 16px;">{{ entity.deliverer.fullName  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Họ và tên người nhận hàng:</div>
                                        <div style="font-size: 16px;">{{ entity.receiver.fullName  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Lý do:</div>
                                        <div style="font-size: 16px;">{{ entity.reason  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 5px 0;">
                                        <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">Xuất tại kho:</div>
                                        <div style="font-size: 16px;">{{ entity.store.storeName  }}</div>
                                    </div>
                                    <div style="display: flex; padding: 5px 0; justify-content: space-between;">
                                        <div style="display: flex; padding: 5px 0;">
                                            <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">
                                                Ngày mượn
                                            </div>
                                            <div style="font-size: 16px;">
                                                <span>{{ entity.loanDate }}</span>
                                            </div>
                                        </div>
                                        <div style="display: flex; padding: 5px 0;">
                                            <div style="font-weight: bold;font-size: 16px; padding-right: 10px;">
                                                Ngày trả
                                            </div>
                                            <div style="font-size: 16px;">
                                                <span>{{ entity.returnDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-left: 10px;">
                                    <div style="font-weight: bold;font-size: 16px;">Hàng hóa bàn giao</div>
                                </div>
                                <div style="display: flex; margin: 10px auto;">
                                    <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                        <thead>
                                            <tr>
                                                <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                <th style="width:120px; padding: 5px 10px; border: 1px solid #e0e0e0;">Mã vật tư</th>
                                                <th style="width:320px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên vật tư</th>
                                                <th style="width:120px; padding: 5px 10px; border: 1px solid #e0e0e0;">Trạng thái</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Đvt</th>
                                                <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Số lượng</th>
                                                <th style="width:170px; padding: 5px 10px; border: 1px solid #e0e0e0;">Ghi chú</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in this.entity.details" :key="'item-'+ item.id">
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{index + 1}}</td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{ item.product.productCode }}</td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 10px;">{{ item.product.productName }}</td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 10px; text-align: center;">
                                                    <span v-if="item.status == 1" style="text-align: center;" >Mới</span>
                                                    <span v-else style="text-align: center;">Cũ</span>
                                                </td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 0; text-align: center;">{{ item.product.unit.unitName }}</td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 0; text-align: center;">{{ item.quantity }}</td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">{{ item.note }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 16px;">Tổng cộng</span></td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 0;text-align: center; "><span style="font-weight: bold; font-size: 16px;">{{ formatNumber(total) }}</span></td>
                                                <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style="display: flex;justify-content: space-between;padding: 20px 30px 50px 30px;">
                                    <div v-for="(item, index) in entity.approveList" :key="'approve-' + index" style="display: flex; flex-direction: column; width: 40%; font-size: 17px; text-transform: none;">
                                        <div style="font-weight: bold; text-align: center;">{{ item.title }}</div>
                                        <div class="approve-status">
                                            <md-chip v-if="item.status == 1" class="md-wait">Chờ duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 2" class="md-primary"><i class="uil uil-check"></i> Đã duyệt</md-chip>
                                            <md-chip v-else-if="item.status == 3" class="md-status-2">Yêu cầu chỉnh sửa</md-chip>
                                            <md-chip v-else-if="item.status == 4" class="md-accent">Không phê duyệt</md-chip>
                                        </div>
                                        <div style="padding-top: 50px; font-weight: bold; text-align: center;">{{ item.staff.fullName }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import storeExportService from '../../../api/storeExportService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Phiếu mượn vật tư'
        },
        data() {
            return {
               title: 'Phiếu mượn vật tư',
               loadding: false,
               entity: {},
               total: 0
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            print () {
                this.$htmlToPaper('store-view');
            },

            async preview () {
                this.$refs.preview.open(this.entity);
            },

            getById(){
                this.setLoading(true);
                storeExportService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.total = 0;
                        for(let i = 0; i < this.entity.details.length; i++){
                            this.total += parseFloat(this.entity.details[i].quantity);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
    })

</script>


<style scoped>
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 26px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 18px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-status {
        padding: 30px 0 0 0;
        text-align: center;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 18px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 18px;
    }
    .line span{
        font-size: 18px;
        font-weight: bold;
    }
    .line div {
        font-size: 18px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 17px;
    }
    .table-1 {
        min-height: 200px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 17px;
    }
</style>


